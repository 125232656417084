import React, { Component } from "react";
import { navigate, Link } from "gatsby";
import "../../../static/styles/_login-modal.scss";
import TakeOver from "../common/Takeover";
import CancelIcon from "@material-ui/icons/Cancel";
import { isLoggedIn, handleLogin } from "../../services/auth";
import Bars from "../../../static/img/assets/bars.svg";
import styled from "styled-components";

let Close = styled(Link)`
  position: absolute;
  top: 0;
  right: 0;
  filter: invert(1);
  svg {
    fill: #fff;
  }
`;

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.input = [];
    this.state = {
      loading: true,
      email: "",
      password: ""
    };
  }
  loginUser = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    handleLogin(this.state)
      .then(res => {
        console.log(res);
        if (isLoggedIn()) {
          navigate("/dashboard/hub/home/");
        }
      })
      .catch(e => {
        alert("Incorrect login");
        this.setState({
          loading: false
        });
      });
  };
  updateForm = (key, e) => {
    let newState = {};
    newState[key] = e.target.value;
    this.setState(newState);
  };

  componentDidMount() {
    if (isLoggedIn()) {
      navigate("/dashboard/hub/home/");
      return;
    } else {
      this.setState({
        loading: false
      });
    }
  }
  render() {
    return (
      <>
        <TakeOver
          onClick={e => {
            e.preventDefault();
            navigate("/");
          }}
        >
          <div
            className="modal"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div className="left-half darkbluebg">
              <h2>Welcome Back!</h2>
            </div>
            <div className="right-half darkbluebg">
              <Close className="closeX" to="/">
                <CancelIcon />
              </Close>
              {this.state.loading && (
                <div
                  style={{
                    color: "#000000",
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    placeContent: "center center",
                    background: "rgba(0, 0, 0, 0.52157)",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "100000"
                  }}
                >
                  <img alt="please wait" src={Bars} />
                </div>
              )}
              <>
                <div>
                  <span className={"screen active"}>
                    <p>
                      Glad to See you again{" "}
                      <span role="img" aria-label="hello">
                        👋
                      </span>
                    </p>
                    <p>Login below</p>
                    <form onSubmit={this.loginUser}>
                      <input
                        autoFocus={true}
                        onChange={this.updateForm.bind(this, "email")}
                        type="email"
                        value={this.state.email}
                        placeholder="email "
                        required
                        autoComplete="email"
                      />
                      <input
                        onChange={this.updateForm.bind(this, "password")}
                        type="password"
                        placeholder="password"
                        autoComplete="current-password"
                        value={this.state.password}
                        required
                      />
                      <div className="button-wrapper">
                        <button
                          disabled={this.state.loading}
                          className="center"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </span>
                </div>
              </>
            </div>
          </div>
        </TakeOver>
      </>
    );
  }
}
